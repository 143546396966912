import { Col } from "react-bootstrap";
import { Like } from "./LikeDislike";
///import { LikeButton } from "./LikeButton";

export const ProjectCard = ({ title, description, imgUrl }) => {
  return (
    <Col sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt="this is the link" />
        <div className="proj-txtx">
          <h4> {title}</h4>
          <span>{description}</span>
        </div>
      </div>
      <Like />
      {/*<LikeButton />*/}
    </Col>
  );
};
