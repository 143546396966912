import { Container, Row, Col } from "react-bootstrap";
//import MailchimpSunscribe from "./MailchimpForm";
import navIcon1 from "../assets/img/nav-icon1.svg";
import github from "../assets/img/github.png";
import navIcon3 from "../assets/img/nav-icon3.svg";
import logoWebpage from "../assets/img/logoWebpage.png";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-item-center">
          {/*<MailchimpForm />*/}
          <Col sm={6}>
            <img src={logoWebpage} alt="logo" />
          </Col>
          <Col sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/in/samuel-riedel/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon1} alt="Linkdn" target="_blank" />
              </a>
              <a
                href="https://github.com/Samuel-Riedel"
                target="_blank"
                rel="noreferrer"
              >
                <img src={github} alt="Github" />
              </a>
              <a
                href="https://www.instagram.com/samuel_riedel"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon3} alt="Instagram" />
              </a>
            </div>
            <p> CopyRight 2024. All Right Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
