import React, { useState } from "react";
import { FcDislike } from "react-icons/fc";
import { FcLike } from "react-icons/fc";

export const Like = () => {
  const [likeCount, setLikeCount] = useState(0);
  const [dislikeCount, setDislikeCount] = useState(0);
  const [activeBtn, setActiveBtn] = useState("none");

  const handleReactionClick = (reaction) => {
    if (activeBtn === "none") {
      if (reaction === "like") {
        setLikeCount(likeCount + 1);
        setActiveBtn("like");
      } else if (reaction === "dislike") {
        setDislikeCount(dislikeCount + 1);
        setActiveBtn("dislike");
      }
    } else if (activeBtn === reaction) {
      if (reaction === "like") {
        setLikeCount(likeCount - 1);
      } else if (reaction === "dislike") {
        setDislikeCount(dislikeCount - 1);
      }
      setActiveBtn("none");
    } else if (activeBtn !== reaction) {
      if (reaction === "like") {
        setLikeCount(likeCount + 1);
        setDislikeCount(dislikeCount - 1);
        setActiveBtn("like");
      } else if (reaction === "dislike") {
        setDislikeCount(dislikeCount + 1);
        setLikeCount(likeCount - 1);
        setActiveBtn("dislike");
      }
    }
  };

  return (
    <div className="likeBox">
      <div className="btn-container">
        <button
          className={`btn likesandDislikes ${
            activeBtn === "like" ? "like-active" : ""
          }`}
          onClick={() => handleReactionClick("like")}
        >
          <FcLike />
          <span className="material-symbols-rounded"></span>
          Like {likeCount}
        </button>

        <button
          className={`btn likesandDislikes ${
            activeBtn === "dislike" ? "dislike-active" : ""
          }`}
          onClick={() => handleReactionClick("dislike")}
        >
          <FcDislike />
          <span className="material-symbols-rounded"></span>
          Dislike {dislikeCount}
        </button>
      </div>
    </div>
  );
};
