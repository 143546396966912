import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";
import { Container, Row, Col } from "react-bootstrap";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section className="skill" id="skills">
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <h2>Skills</h2>
              <p>
                {" "}
                From 2023 to 2025, I have pursued an intensive learning path as
                a self-taught developer, beginning on 2023 with the MigraCode
                FullStack Developer Bootcamp in Barcelona, where I developed a
                strong foundation in both frontend and backend web development.
                I further expanded my expertise in 2024 through hands-on
                training in TensorFlow.js at Porsche Digital, gaining valuable
                insights into AI and machine learning applications. That same
                year, I deepened my Python skills with New Relic, mastering
                fundamentals and exploring advanced topics like machine
                learning, Django, and data visualization. Additionally, I gained
                practical experience in Test-Driven Development (TDD) at Porsche
                Digital, applying TDD principles in real-world scenarios.
                Currently, I am completing the "100 Days of Python" program with
                The App Brewing Company, focusing on data science, automation,
                and app development, with an expected completion in February
                2025.
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="skill-slider"
              >
                <div className="item">
                  <img src={meter1} alt="meteor1" />
                  <h5> Video Editor</h5>
                </div>

                <div className="item">
                  <img src={meter2} alt="meteor2" />
                  <h5> Web Development</h5>
                </div>

                <div className="item">
                  <img src={meter3} alt="meteor3" />
                  <h5> 3D Design</h5>
                </div>

                <div className="item">
                  <img src={meter1} alt="meteor4" />
                  <h5> Photographer</h5>
                </div>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-left"
        src={colorSharp}
        alt="Decorative background pattern"
      />
    </section>
  );
};
