import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../assets/img/header-img.svg";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const toRotate = ["Web Developer", "Web dev", "Webdev"];
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);
    return () => {
      clearInterval(ticker);
    };
    // eslint-disable-next-line
  }, [text, delta]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updateText = isDeleting
      ? fullText.substring(0, text.length - 1) // removed comma before -1
      : fullText.substring(0, text.length + 1);

    setText(updateText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updateText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updateText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(500);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <span className="tagline">Welcome to my Portfolio </span>
            <h1>
              {"Hi, I am Samuel Riedel, a junior"}{" "}
              <span className="wrap">{text}</span>
            </h1>
            <p>
              As a recent graduate of the MigraCode bootcamp in Barcelona, I
              have developed a strong foundation in programming principles, with
              hands-on experience in both frontend and backend development. I
              approach my work and studies with enthusiasm and dedication,
              always striving to apply my technical and interpersonal skills to
              foster a positive working environment for myself and my
              colleagues.
              <br></br>
              <br></br>I value adaptability and open-mindedness, especially when
              it comes to receiving constructive criticism and embracing new
              learning opportunities. I view feedback as a vital tool for both
              personal and professional growth, enabling me to tackle challenges
              with resilience and a commitment to continuous improvement.{" "}
            </p>
            <button
              onClick={() => {
                const subject = encodeURIComponent("Let's Connect");
                const body = encodeURIComponent(
                  "Hi there,\n\nI would like to connect with you..."
                );
                window.open(
                  `mailto:samuel.riedel@outlook.com?subject=${subject}&body=${body}`
                );
              }}
            >
              Let's Connect <ArrowRightCircle size={25} />
            </button>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img src={headerImg} alt="Header Img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
